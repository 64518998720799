
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        












































































.my-ean-info,
[class*='my-ean-info--'] {
  display: flex;
  margin-right: col(-1);
  margin-left: col(-1);
  padding: 1.5rem col(1);
  border-bottom: 1px solid $c-gray-light;

  @include mq($until: s) {
    flex-wrap: wrap;
  }

  @include mq(l, xl) {
    margin-right: col(-2);
    margin-left: col(-2);
  }

  @include mq(xl) {
    margin-right: col(-1, 16);
    margin-left: col(-1, 16);
    padding: 1.5rem col(1, 16);
  }

  @include mq(wrapper) {
    margin-right: 0;
  }
}

.my-ean-info__meter,
.my-ean-info__address {
  display: flex;
  align-items: center;
}

.my-ean-info__address {
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding-right: 3rem;

  @include mq(s, l) {
    margin-bottom: 0;
    border-right: 1px solid $c-gray-light;
  }

  @include mq(xl) {
    margin-bottom: 0;
    border-right: 1px solid $c-gray-light;
  }
}

.my-ean-info__meter {
  margin-left: -0.5rem;
}

.my-ean-info__address__icon {
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 1.3rem;
  fill: $c-main-primary;
}

.my-ean-info__meter__icon {
  width: 4.8rem;
  height: 4.8rem;

  &.gaz {
    fill: $c-orange;
  }

  &.elec {
    fill: $c-yellow;
  }
}

.my-ean-info__meter__info {
  margin-left: 2.5rem;
}
